
.task-data {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 30px 0;
    font-size: 14px;
    color: #333;
    .container {
        flex: 1;
        box-sizing: border-box;
        padding: 0 30px;
    }
    .filters {
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #DADCE7;   
        .select {
            display: flex;
            align-items: center;
            margin-right: 20px;
            .label {
                margin-right: 10px;
            }
        }
    }
    .ECharts {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 339px;
        margin: 30px 0 40px;
        .average,
        .level {
            border: 1px solid #DADCE7;
            border-radius: 6px;
        }
        .average {
            flex: 55.3;
            margin-right: 30px;
        }
        .level {
            flex: 100.4;
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: relative;
            .ratio-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 16px;
                color: #000;
                .ratio {
                    margin: 26px 0 20px;
                }
                .count {
                    font-size: 14px;
                    color: #666;
                }
            }
        }
    }
    .ranking {
        .title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 30px;
        }
        .desc {
            margin: 0 0 20px 30px;
        }
    }
}
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
